


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import Select from "react-select";
import "react-folder-tree/dist/style.css";
import { addNewReceipt, getAccountledger, getReceiptByGuid } from "../../services/AccountApiServices.js";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ReceiptEntry } from '../../core/json/ReceiptEntry.js';
import { Company } from "../../core/json/Company.js";
import { getCurrencyList } from "../../services/MasterApiServices.js";
import { Calendar, PlusCircle } from "feather-icons-react/build/IconComponents/index.js";
import{ FinancialYear }from "../../core/json/FinancialYear.js"
import{ Currency } from "../../core/json/Currency.js"
import { fetchBranchSettings } from '../../redux/settingbyBranch';
import dayjs from "dayjs";
import { DatePicker } from "antd";
const Receipt = ({ mode, data, handleClose, handleRefresh }) => {
  const dispatch = useDispatch()
  const { branch } = useSelector((state) => state.branchlist);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const { userId } = useSelector((state) => state.userauth);
  const [ledgerData, setLedgerData] = useState();
  const[currencyData,setCurrencyData]= useState()
  const [isRowAdded, setIsRowAdded] = useState(false);
  const { branchsettings } = useSelector((state) => state.settings);
console.log(companyInfolist,"oooooo");

  const { register, handleSubmit,setValue,getValues,watch, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    handleLedgerList();
    handleCurrencyList();
    // handleAddRow()
    
    dispatch(fetchBranchSettings(branch.guid))
  }, []);
console.log(currencyData,"vvvvvvv");
console.log(branch,"uuuuuuu");
console.log(data,"rrrrrrrr");


useEffect(() => {
  if (mode === "add") {
    handleEntryNo();
    if (!isRowAdded && fields?.length === 0) {
      handleAddRow();
      setIsRowAdded(true);
    }
  }


  // handleProductsList();
  // handleTaxList();
  // handleUnitList();
  // handleDiscountList();
  // dispatch(fetchSettings(branch));
  // const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
  // const currfmt = match ? match[0]?.length - 1 : 0;
  // setDecimalPoints(currfmt);
}, []);

useEffect(() => {
  resetmodal();
}, [data,data.ledgerID]);




const handleAddRow = () => {
  append({
    narration: "",
    amount: "0",
    ledgerID: null,
  });
};

const handleEntryNo = async () => {
  try {
    const response = await getReceiptByGuid(branch,0);
    console.log(response,"WWWWW");
    const receiptData = response;
    // Find the maximum number from the existing entries
    const maxEntryNumber =
      receiptData?.length > 0
        ? receiptData?.map((data) => parseInt(data.no)) 
            .reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
        : 0;
    let counter = maxEntryNumber + 1;
    setValue(`no`, counter);
    counter++;
  } catch (error) {
    console.error("Failed to fetch purchase invoices", error);
  }
};


function resetmodal() {
  if (mode === "edit" && data) {
  console.log(data);
  
    // Populate form with data for editing
    //purchase fields population
    setValue("no", data.no);
    setValue("narration", data.narration);
    setValue("cash", data.ledgerID);
    setValue("amount", data.amount);
    setValue("date", dayjs(data.date)); 
    
    

    setValue(
      "items",
      data?.receiptDetails?.map((detail, i) => ({
        ledgerID:detail.ledgerID,
        narration: detail.narration,
      amount: detail.amount,
        
      }))
    );
  }
}




  const handleFormSubmit = async (formdata) => {
    const objreceipt = new ReceiptEntry();
    if (mode === "edit") {
      console.log("edit mode on--------");
      console.log(data.guid, "data.guid");

      objreceipt.guid = data.guid;
      objreceipt.id = data.id;
    }
    const objfinancialyear = new FinancialYear();
    const objcurrency= new Currency()
    objreceipt.FinancialYear= objfinancialyear;
    objcurrency.guid= branchsettings?.primaryCurrencyGUID;
    objreceipt.No = formdata.no;
    objreceipt.Date = formdata.date;
    objreceipt.LedgerID = formdata.cash;
    objreceipt.TotalAmt = formdata.totalAmt;
    objreceipt.Narration = formdata.narration;
    objreceipt.IsCheque = false;
    objreceipt.Currency = objcurrency
    objreceipt.CurrencyRate = 0;
    objreceipt.Branch = branch;
    objreceipt.updatedUser = `{${userId?.id}}`;
    objreceipt.Company = companyInfolist[0];
    console.log(branchsettings, "Currency")

    const detailObj = formdata?.items?.map(e => ({
        ledgerID : e.ledgerID,
        Amount: e.amount,
        Narration: e.narration, 
    }));

    objreceipt.receiptDetails = detailObj;

    try {
      const response = await addNewReceipt(objreceipt);
      console.log(objreceipt,"ggggg");
      
      if (response.data === true) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose();
      } 
      else {
        toast.error("Operation failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "Something went wrong");
      console.error("Error handling receipt:", error);
    }
  };



  const handleLedgerList = async () => {
    try {
      const response = await getAccountledger();
      if (Array.isArray(response)) {
        setLedgerData(response?.map(e=>({
          value: e.guid,
          label: e.name,
        })));
      } else {
        setLedgerData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "Something went wrong");
      setLedgerData([]);
    }
  };
  const handleCurrencyList = async () => {
    try {
      const response = await getCurrencyList();

      if (Array.isArray(response)) {
        setCurrencyData(response);
      } else {
        setCurrencyData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setCurrencyData([]);
    }
  };
  const handleAmount = (value, index) => {
    // Update the amount for the specific item
    setValue(`items[${index}].amount`, value);
  
    // Calculate the total by summing all item amounts
    const items = getValues("items"); // Assume getValues fetches the current list of items
    let total = items.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);
  
    // Update the total value
    setValue("totalAmt", total);
  };
  const handleTotal = () => {
    const items = getValues("items"); // Retrieve the current list of items
    let total = items.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);
    
    // Update the total field with the new calculated value
    setValue("totalAmt", total);
  };
  
  // Define the onClick function to remove an item and update the total
  const handleRemove = (index) => {
    remove(index); // Remove the item at the specified index
    handleTotal(); // Recalculate the total after the item is removed
  };
  return (
    <div className="modal fade show"
    style={{ display: "block" }}
    tabIndex="-1" >
      <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
              <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Receipt"
                        : "Add Receipt"}
                    </h4>
                <button type="button" className="close" onClick={handleClose}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label>No <span className="text-danger">*</span></label>
                      <input type="text" 
                      className="form-control" {...register("no")} required />
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label>Currency <span className="text-danger">*</span></label>
                      <select {...register("currency", { required: "Currency is required" })} className="form-control">
                        <option value="">Select currency</option>
                        {currencyData?.map(currency => (
                          <option key={currency.guid} value={currency.guid}>
                            {currency.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  <div className="row">
                  <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                   
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label className="form-label">
                          cash/bank<span className="text-danger">*</span>
                        </label>
                        <div className="row">
                          <Controller
                            control={control}
                            name={`cash`}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                classNamePrefix="react-select"
                                options={ledgerData}
                                value={ledgerData?.find(
                                  (ledgerData) => ledgerData?.value === value
                                )}
                                onChange={(selectedOption) =>
                                  onChange(selectedOption.value)
                                }
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                required
                              />
                            )}
                          />
                          </div>
                   
                  </div>
                  <div className="table-responsive mb-3">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Ledger</th>
                          <th>Amount</th>
                          <th>Narration</th>
                        </tr>
                      </thead>
                      <tbody>
                      {fields?.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].ledgerID`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={ledgerData}
                                          value={ledgerData?.find(
                                            (option) => option.value ===value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            // handleEachProductfetch(
                                            //   selectedOption,
                                            //   index
                                            // );
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].amount`)}
                                      onChange={(e)=>handleAmount(e.target.value,index)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].narration`)}
                                      
                                    />
                              </td>
                               <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => handleRemove(index)}
                                     >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                               </td>
                            </tr>
                          );
                            })}
                      </tbody>
                    </table>
                  </div>
                  <div>
                        <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                          <button
                            className=" btn btn-dark add-icon tab  mt-3 "
                            type="button"
                            onClick={handleAddRow}
                          >
                            <PlusCircle className="feather-plus-circles" />
                          </button>
                        </div>
                      </div>

                  <div className="form-group">
                    <label>Remark</label>
                    <input type="text" className="form-control w-50" {...register("narration")} />
                  </div>

                  <div className="form-group">
                    <label>Total</label>
                    <input type="text" className="form-control w-50" {...register("totalAmt")} />
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
