import { DomainBase } from "./DomainBase";

export class MenuPermissions extends DomainBase {
    constructor() {
        super();
        this.Menu = null;
        this.Edit = false;
        this.View = false;
        this.Deletion = false;
        this.Add = false;
    }
}