import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ModifierClass } from "../../../core/json/Modifier";
import {
  editModifier,
  addModifier,
  getModifierCategoryList,
} from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";

const ModifierModal = ({ mode, data, handleClose, handleRefresh }) => {
  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [ModifiercategoryList, setModifierCategoryList] = useState([]);

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("name", data.name);
      setValue("rate", data.rate);
      setValue("modifierCategory", data.modifierCategory.guid || ""); // Default value handling
    } else {
      reset();
    }

    // Fetch category list
    getModifierCategoryList().then(setModifierCategoryList).catch(console.error);
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a modifier
  const handleFormSubmit = async (formData) => {
    const objModifier = new ModifierClass();
    objModifier.name = formData.name;
    objModifier.rate = Number(formData.rate);
    objModifier.ModifierCategory = ModifiercategoryList.find(
      (cat) => cat.guid === formData.modifierCategory
    );
    objModifier.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; // userId
    // objModifier.branch = branch;
    if (mode === "edit") {
      objModifier.guid = data.guid;
      objModifier.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editModifier(objModifier)
          : await addModifier(objModifier);

      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      } else if (response == false) {
        toast.error("false")
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling modifier:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog  modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Modifier" : "Add Modifier"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                <span className="text-danger">{ModifiercategoryList?.length<1 && 'Modifier Category is empty, to continue add modifier category'}</span>
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Rate<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            step="any"
                            placeholder="Rate"
                            {...register("rate", {
                              required: "Rate is required",
                              min: {
                                value: 0,
                                message: "Rate cannot be negative",
                              },
                            })}
                          />
                          {errors.rate && <p className="text-danger">{errors.rate.message}</p>}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Category<span className="text-danger">*</span>
                          </label><select
                            {...register("modifierCategory", { required: true })}  // Register with validation rules
                            value={watch("modifierCategory") || data?.modifierCategory?.guid || ""}  // `watch` is from react-hook-form to track the current form state
                            onChange={(e) => setValue("modifierCategory", e.target.value)}  // `setValue` updates the form state manually
                            className="form-control"  // Bootstrap class for styling
                          >
                            <option value="" disabled>
                              Select Category
                            </option>
                            {ModifiercategoryList && ModifiercategoryList?.map((category) => (
                              <option key={category.guid} value={category.guid}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Category<span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("modifierCategory", {
                              required: "Category is required"  // Validation rule with a custom message
                            })}
                            value={watch("modifierCategory") || data?.modifierCategory?.guid || ""}
                            onChange={(e) => setValue("modifierCategory", e.target.value)}
                            className={`form-control ${errors.modifierCategory ? 'is-invalid' : ''}`}  // Add Bootstrap invalid class for error styling
                          >
                            <option value="" disabled>Select Category</option>
                            {ModifiercategoryList?.map((category) => (
                              <option key={category.guid} value={category.guid}>
                                {category.name}
                              </option>
                            ))}
                          </select>

                          {errors.modifierCategory && (
                            <div className="invalid-feedback">
                              {errors.modifierCategory.message}  
                            </div>
                          )}
                        </div>
                      </div> */}

                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierModal;
