import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { CounterSection } from '../../../core/json/CounterSection';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { addNewCounterSection, getCounterSection } from '../../../services/MasterApiServices';
import { CounterClass } from '../../../core/json/Counter';
import { CounterSectionAssignmentInfo } from '../../../core/json/CounterSectionAssignmentInfo';
// function AddCounterSection() {
const AddCounterSection = ({ data }) => {
  const animatedComponents = makeAnimated();
  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  // Get user from redux
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [sections, setsections] = useState([])
  const [defaultsection, setdefaultsection] = useState()
  const { sectionList } = useSelector((state) => state.usersetting);
  const closeButtonRef = useRef();
  const formatedSectionList = sectionList?.map(e => ({
    value: e.guid,
    label: e.name
  }))
  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  function resetmodal() {

  }
  useEffect(() => {
    resetmodal();
    if(data){

      handlecounterdetail()
    }
  }, [data,setValue]);
const handlecounterdetail =async()=>{
  const objCounter = new CounterClass();
  
    objCounter.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; // userId
    objCounter.branch = branch;
    objCounter.guid = data?.guid;
  try{

    const response = await getCounterSection(objCounter)
    console.log(response,"dddddddddd");
    
    if(response?.counterSection[0]){
      const IdArray = response.counterSection?.map((e)=>e.sectionID  )
      const defobj = response.counterSection?.find((e)=>e.isDefault==true  )
      const filteredList = formatedSectionList.filter((e) => IdArray.includes(e.value));
      const defSection= formatedSectionList.find((e)=>e.value==defobj.sectionID)
      setsections(filteredList)
      setdefaultsection(defSection)
    }
    else{
      setsections()
      setdefaultsection()

    }
  }
 catch (error) {
    // toast.error(error?.response?.data?.Message);
    console.error("Error handling category:", error?.response?.data?.Message);
  }
}

  console.log(sectionList);

  const handleFormSubmit = async (formData) => {
    const objcountersection = new CounterClass();
    const Assignedsection = sectionList?.filter(e =>
      sections?.map(obj => obj.value)?.includes(e.guid)
    );

    const defSection = sectionList?.find(e => defaultsection?.value == e.guid)

    console.log(Assignedsection);

    for (const row of Assignedsection) {
      const assignCounter = new CounterSectionAssignmentInfo();
      assignCounter.SectionID = row.guid;
      assignCounter.IsDefault = row?.guid == defSection.guid ? true : false;
      objcountersection.counterSection.push(assignCounter);
    }

    objcountersection.updatedUser = `{${userId?.id}}`;
    objcountersection.branch = branch;
    objcountersection.guid = data?.guid;

    try {
      const response = await addNewCounterSection(objcountersection);
      if (response == true) {

        toast.success("success")
        closemodal()
      }
      else {
        toast.success(response.data)
      }

    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };


  const handlesections = (e) => {
    console.log(e);
    setsections(e)
    if (e?.length > 0) {
      setdefaultsection(e[0])
    }
  }

  return (
    <div>
      <div
        className="modal fade"
        id="add-countersection">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">

                    <h4>COUNTER SECTION ({data?.name})</h4>

                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-12 ">
                        <div className="input-blocks">
                          <label>
                            Select section
                          </label>
                          <Select

                            closeMenuOnSelect={false}
                            isMulti
                            components={animatedComponents}
                            placeholder="Select section"
                            value={sections}
                            onChange={handlesections}
                            options={formatedSectionList}

                          />
                        </div>
                      </div>
                    </div>
                    {sections?.length > 1 &&
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Default section
                          </label>
                          <Select
                            // defaultValue={defaultbranch}
                            value={defaultsection}
                            onChange={setdefaultsection}
                            placeholder="Select Default Branch"

                            options={sections}
                          />
                        </div>
                      </div>
                    }


                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCounterSection