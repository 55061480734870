import axios from "axios";

const base_path = process.env.REACT_APP_API_URL;

//Accounts.......

export const getReceiptByGuid = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Receipt/GetAllByBranchID?globalBranchID=${branch.guid}`
  );
  return response.data;
};
export const addNewReceipt = async (receiptdata) => {
  console.log(receiptdata, "qqqqqq");

  const response = await axios.post(
    `${base_path}/Api/Receipt/SaveOrUpdate`,
    receiptdata
  );
  return response;
};
export const getPaymentByGuid = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Payment/GetAllByBranchID?globalBranchID=${branch.guid}`
  );
  return response.data;
};
export const addNewPayment = async (paymentdata) => {
  const response = await axios.post(
    `${base_path}/Api/Payment/SaveOrUpdate`,
    paymentdata
  );
  return response;
};
export const getAccountledger = async () => {
  const response = await axios.get(`${base_path}/Api/AccLedger/GetAll`);
  return response.data;
};
export const addAccountLedger = async (ledgerdata) => {
  console.log(ledgerdata, "ttttttt");

  const response = await axios.post(
    `${base_path}/Api/AccLedger/SaveOrUpdate`,
    ledgerdata
  );
  return response;
};

export const getAccountgroup = async () => {
  const response = await axios.get(`${base_path}/Api/AccGroup/GetAll`);
  return response.data;
};

export const addAccountgroup = async (groupdata) => {
  console.log(groupdata);

  const response = await axios.post(
    `${base_path}/Api/AccGroup/SaveOrUpdate`,
    groupdata
  );
  return response;
};
