import { DomainBase } from "./DomainBase";

export class Menu extends DomainBase{
    constructor() {
        super();
        this.Name = '';
        this.ShortName = '';
        this.ParentID = 0;
        this.Module = '';
        this.Active = false;
        this.Index = 0;
        this.Image = '';
        this.OtherLanguage = '';
    }
}