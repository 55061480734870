import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
  RefreshCw,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { Pagination, Tabs } from "antd";
import {
  deleteReasonType,
  getReasonTypeguid,
  getReasonTypeList,
} from "../../services/MasterApiServices";
import ReasonTypeModal from "../../components/master/modal/ReasonTypeModal";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import Reciept from "./Reciept";
import { getReceiptByGuid } from "../../services/AccountApiServices";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";
import Receipt from "./Reciept";

function ReceiptMaster() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const[receiptData,setReceiptData] = useState()
  const [itemwiseData, setitemwiseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ReasonTypeData, setReasonTypeData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { branch } = useSelector((state) => state.branchlist);
  const [permit, setpermit] = useState();
  const {menuItems} = useSelector((state) => state.amc);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Receipt"))
    handleReceiptList();
  }, []);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(receiptData)
    ? receiptData?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];
 

   
console.log(receiptData,"ddddaaaa");
console.log(updateData,"bbbbbbbb");


const handleTabChange = (key) => {
  console.log("Active Tab:", key);
  setActiveTab(key);
  if (key == 4) {
    setIsFilterVisible(false);
  } else {
    setIsFilterVisible(true);
  }
};


  const handleReceiptList = async () => {
    setLoading(true);
      try {
        const response = await getReceiptByGuid(branch);
  
        if (Array.isArray(response)) {
     
        
          setReceiptData(response);
          const allItemwiseData = response.flatMap((invoice) =>
            itemwiseDataFn(invoice)
          );
          setitemwiseData(allItemwiseData);
          setLoading(false)
        } else {
          setReceiptData([]); 
          setLoading(false)// If response is not an array, reset to an empty array
        }
      } catch (error) {
        console.log(error?.response?.data?.Message || "something went wrong");
        setReceiptData([]);
        setLoading(false)
      }
    };



  const handleEditClick = async (guid) => {
    try {
      const ReceiptByGuid = receiptData.find((data) => data.guid === guid);
     
      setUpdateData(ReceiptByGuid); // Set the data for editing
      setModalMode("edit");
      console.log(modalMode);
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching modifier reason type by GUID:",
        error?.response?.data?.Message
      );
    }
  };
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        try {
          const response = await deleteReasonType(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The reason type has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              // handleReasonTypeList(); // Refresh the list
              // setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      sorter: (a, b) => a.no?.length - b.no?.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      // sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      // sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : [])
  ];
  const Summerycolumns = [
    {
      title: "No",
      dataIndex: "no",

      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Remark",
      dataIndex: "narration",
      sorter: (a, b) => a.narration?.length-b.narration?.length,

    },
    {
      title: "Total",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.grossTotal - b.grossTotal,
    },
   ];
  const DetailedColumns = [
    {
      title: " No",
      dataIndex: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    
    {
      title: "Ledger",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.name?.localeCompare(b.ledgerName?.name),
      
    },
    {
      title: "Total",
      dataIndex: "amount",
      sorter: (a, b) => a.invoiceNo?.length - b.invoiceNo?.length,
    },
    
   
   
  ];
  const itemwiseDataFn = (invoice) => {
    return invoice.receiptDetails?.map((item, index) => {
      return {
        
        no: invoice.no,
        date: invoice.date,
        ledgerName: item.ledgerName || "Unknown Item",
       amount: item.amount,
        
      };
    });
  };

 
  const MySwal = withReactContent(Swal);
  return (
    <div>
      <div>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Receipt</h4>
                  <h6>Manage Receipt Type here</h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("PDF")}
                  >
                    <Link
                      onClick={() => exportPDF(ReasonTypeData, "Reason Type")}
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Excel")}
                  >
                    <Link
                      onClick={() => exportExcel(ReasonTypeData, "Reason Type")}
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Print")}
                  >
                    <Link
                      onClick={() => HandlePrint(ReasonTypeData, "Reason Type")}
                    >
                      <i data-feather="printer" className="feather-printer" />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Refresh")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      // onClick={() => handleReasonTypeList()}
                    >
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Collapse")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      id="collapse-header"
                      className={togglehead ? "active" : ""}
                      onClick={handletogglehead}
                    >
                      <ChevronUp />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              {permit?.add && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode("add"); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                  }}
                >
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
              
         
              
            </div>
            {/* /product list */}
            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <Link to="#" className="btn btn-searchset">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-search"
                        >
                          <circle cx={11} cy={11} r={8} />
                          <line x1={21} y1={21} x2="16.65" y2="16.65" />
                        </svg>
                      </Link>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          {" "}
                          <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search"
                            aria-controls="DataTables_Table_0"
                            value={searchText}
                            onChange={handleSearch}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}

                {/* /Filter */}
                <div className="table-responsive">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                  >
                    <PulseLoader
                      color="#ff9e42"
                      size={12}
                      speedMultiplier={1}
                    />
                  </div>
                ) : (
                  <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                    <TabPane tab="Receipt" key="1">
                      <Table columns={columns} dataSource={receiptData} />
                    </TabPane>
                    <TabPane tab="Summery Reports" key="2">
                      <Table
                        columns={Summerycolumns}
                        dataSource={receiptData}
                      />
                    </TabPane>
                    <TabPane tab="Detailed Reports" key="3">
                      <Table
                        columns={DetailedColumns}
                        dataSource={itemwiseData}
                      />
                    </TabPane>
                   
                  </Tabs>
                )}
              </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
        <Pagination />
        {modalOpen && (
        <Receipt
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleReceiptList();
            setSearchText("");
          }}
        />
      )}
        {/* <Reciept /> */}
      </div>
    </div>
  );
}

export default ReceiptMaster;