import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
  RefreshCw,
  Calendar,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { DatePicker, Pagination, Select, Tabs } from "antd";
import {
  deleteReasonType,
  getReasonTypeguid,
  getReasonTypeList,
} from "../../services/MasterApiServices";
import ReasonTypeModal from "../../components/master/modal/ReasonTypeModal";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import Reciept from "./Reciept";

import PaymentModal from "../../components/accounting/PaymentModal";
import {
  getAccountledger,
  getPaymentByGuid,
} from "../../services/AccountApiServices";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";

function PaymentMaster() {
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const [selectedLedger, setSelectedLedger] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState();
  const [ledgerData, setLedgerData] = useState();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [PaymentData, setPaymentData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filtereditemData, setFiltereditemData] = useState([]);
  const { togglehead } = useSelector((state) => state.headerToggle);

  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    handlePaymentList();
    handleLedgerList();
  }, []);

  const itemwiseDataFn = (data) => {
    return data.paymentDetails?.map((item, index) => {
      return {
        date: data.date,
        no: data.no,
        ledger: item.ledgerName || "Unknown ledger",
        amount: item.amount || 0.0,
      };
    });
  };

  const handlePaymentList = async () => {
    try {
      const response = await getPaymentByGuid(branch);
      if (Array.isArray(response)) {
        setPaymentData(response);
        const allItemwiseData = response.flatMap((data) =>
          itemwiseDataFn(data)
        );
        setitemwiseData(allItemwiseData);
        setFiltereditemData(allItemwiseData);
      } else {
        setPaymentData([]); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setPaymentData([]);
    }
  };
  const handleTabChange = (key) => {
    console.log("Active Tab:", key);
    setActiveTab(key);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleLedgerList = async () => {
    try {
      const response = await getAccountledger();
      if (Array.isArray(response)) {
        setLedgerData(
          response?.map((e) => ({
            value: e.guid,
            label: e.name,
          }))
        );
      } else {
        setLedgerData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "Something went wrong");
      setLedgerData([]);
    }
  };
  useEffect(() => {
    handleFilter();
  }, [searchText]);

  // const modifiedList = Array.isArray(PaymentData)
  //   ? PaymentData?.filter((value) =>
  //       value.name?.toLowerCase().includes(searchText?.toLowerCase())
  //     )
  //   : [];

  const handleEditClick = (guid) => {
    try {
      const paymentByGuid = PaymentData.find((data) => data.guid === guid);

      setUpdateData(paymentByGuid);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching payment by GUID:",
        error?.response?.data?.Message
      );
    }
  };
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        try {
          const response = await deleteReasonType(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The reason type has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              // handleReasonTypeList(); // Refresh the list
              // setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Entry#",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },
    {
      title: "Remarks",
      dataIndex: "narration",
      sorter: (a, b) => a.narration - b.narration,
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
    },
    // ...(permit?.edit || permit?.deletion
    //   ? [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* {permit?.edit && ( */}
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* )}
            {permit?.deletion && ( */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj.id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
            {/* )} */}
          </div>
        </div>
      ),
    },
  ];
  //     : []),
  // ];

  const columnsWithoutActions = [
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },
    {
      title: "Remarks",
      dataIndex: "narration",
      sorter: (a, b) => a.narration - b.narration,
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
      align: "right",
    },
  ];
  const detailedColumns = [
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Ledger",
      dataIndex: "ledger",
      sorter: (a, b) => a.ledger?.length - b.ledger?.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      align: "right",
    },
  ];
  const MySwal = withReactContent(Swal);
  // function handleReset() {
  //   setFromDate(null);
  //   setToDate(null);
  //   setselectedLedger(null);
  //   setSelectedSupplier(null);
  //   setSearchText("");
  //   setFilteredData(purchaseData);
  //   setFiltereditemData(itemwiseData);
  // }
  function handleLedgerChange(e) {
    setSelectedLedger(e.target.value);
  }
  function handleFilter() {
    console.log("Original Data:", PaymentData);
    console.log("Search Text:", searchText);
    console.log("From Date:", fromDate, "To Date:", toDate);
    console.log("selectedLedger:", selectedLedger);
    // console.log("Selected Supplier:", selectedSupplier);

    const filteredData = PaymentData?.filter((entry) => {
      console.log("Current Entry:", entry);

      const ledgerMatch =
        !selectedLedger ||
        entry?.paymentDetails?.some(
          (detail) => detail?.product?.guid === selectedLedger
        );
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      //search
      const searchTextMatch = Object.values(entry).some((val) => {
        const stringMatch =
          typeof val === "string" && val.toLowerCase().includes(searchText);
        const numberMatch =
          typeof val === "number" && val.toString().includes(searchText);

        return stringMatch || numberMatch; // Return true if either matches
      });
      return ledgerMatch && fromDateMatch && toDateMatch && searchTextMatch;
    });

    console.log("filtered Data:", filteredData);
    setFilteredData(filteredData);

    const allItemwiseData = filteredData.flatMap((invoice) =>
      itemwiseData(invoice)
    );
    setFiltereditemData(allItemwiseData);
  }
  return (
    <div>
      <div>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Payment</h4>
                  <h6>Manage Payment here</h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("PDF")}
                  >
                    <Link onClick={() => exportPDF(PaymentData, "Reason Type")}>
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Excel")}
                  >
                    <Link
                      onClick={() => exportExcel(PaymentData, "Reason Type")}
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Print")}
                  >
                    <Link
                      onClick={() => HandlePrint(PaymentData, "Reason Type")}
                    >
                      <i data-feather="printer" className="feather-printer" />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Refresh")}
                  >
                    <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    // onClick={() => handleReasonTypeList()}
                    >
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Collapse")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      id="collapse-header"
                      className={togglehead ? "active" : ""}
                      onClick={handletogglehead}
                    >
                      <ChevronUp />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              <ul className="table-top-head"></ul>
              <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    onClick={() => {
                      setModalMode("add"); // Set the modal mode to 'add'
                      setModalOpen(true); // Open the modal
                    }}
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              </div>
            </div>

            <div className="card table-list-card">
              <div className="card-body">
                {/* /Filter */}
                <div className="row gx-2 gy-2 align-items-center">
                  {/* Search Input and Time Pickers */}
                  <div className="col-lg-3 col-md-6 col-12 me-1 ms-2">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm me-2 formsearch "
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 d-flex ms-2">
                    {" "}
                    <div className="input-blocks">
                      <label>Ledger</label>
                      <div className="input-groupicon ">
                        <select
                          value={selectedLedger}
                          onChange={handleLedgerChange}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          {ledgerData?.map((ledger) => (
                            <option key={ledger.value} value={ledger.value}>
                              {ledger.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12  d-flex justify-content-end">
                    <button
                      className="btn btn-submit rounded-2  me-2"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                    <button
                      className="btn btn-reset rounded-2"
                      // onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                {/* </div> */}

                <div className="card-body py-3">
                  <div className="row gx-2 gy-2">
                    <div className="col-lg-3 col-sm-6 mx-2">
                      <label>From Date</label>
                      <div className="input-group">
                        <DatePicker
                          className="form-control"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          // onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="input-group-text">
                          <Calendar className="info-img" />
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 mx-2">
                      <label>To Date</label>
                      <div className="input-group">
                        <DatePicker
                          className="form-control"
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          // onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="input-group-text">
                          <Calendar className="info-img" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* /Filter */}
                <div className="table-responsive product-list">
                  <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                    <TabPane tab="Payment" key="1">
                      <Table columns={columns} dataSource={filteredData} />
                    </TabPane>
                    <TabPane tab="Summary Reports" key="2">
                      <Table
                        columns={columnsWithoutActions}
                        dataSource={filteredData}
                      />
                    </TabPane>
                    <TabPane tab="Detailed Reports" key="3">
                      <Table
                        columns={detailedColumns}
                        dataSource={filtereditemData}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
        <Pagination />
        {modalOpen && (
          <PaymentModal
            mode={modalMode}
            data={modalMode === "edit" ? updateData : {}}
            handleClose={() => setModalOpen(false)}
            handleRefresh={() => {
              handlePaymentList();
              setSearchText("");
            }}
          />
        )}
      </div>
    </div>
  );
}

export default PaymentMaster;
