import React, { useEffect, useState } from "react";
import FolderTree from "react-folder-tree";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChevronUp, PlusCircle, RotateCcw } from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../core/img/imagewithbasebath.jsx";
import { Link } from "react-router-dom";
import { renderTooltip, exportExcel, exportPDF, HandlePrint } from "../../redux/helper.js";
import AddGroup from "./AddGroup.jsx";
import { addAccountLedger, getAccountgroup, getAccountledger } from "../../services/AccountApiServices.js";
import { AccLedger } from '../../core/json/AccLedger.js';
import { Company } from "../../core/json/Company.js";
import { AccGroup } from "../../core/json/AccGroup.js";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import Test from "../transactions/test.js/test.js";

const Ledger = () => {
  const { register, handleSubmit, reset, getValues, watch, setValue,formState: { errors, isValid, isDirty }, } = useForm({
    defaultValues: {
      name: '',
      amount: '',
      group: '',
      isDebit: false
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const [GroupData, setGroupData] = useState([]);
  const [ledgerData, setLedgerData] = useState(null);
  const [groupAlign, setgroupAlign] = useState(null);
  const [treeData, settreeData] = useState();
  const [LedgerMode, setLedgerMode] = useState('add');
  const [groupMode, setgroupMode] = useState('Add');
  const [singleLedger, setsingleLedger] = useState();
  const [modelOpen, setmodelOpen] = useState(false);
  useEffect(() => {
    handleLedgerList();
    handleGroupList();
  }, []);

  useEffect(() => {
    handleLedgerList();
    handleGroupList();
  }, []);

  useEffect(() => {
    if (groupAlign && ledgerData) {
      align();
    }
  }, [groupAlign, ledgerData]);
  const organizeGroupsByParent = (data) => {
    // Step 1: Create a map of all nodes by their GUID
    const nodesMap = data.reduce((map, item) => {
      map[item.guid] = { ...item, children: [],type: 'group' };
      return map;
    }, {});

    let rootNodes = [];

    // Step 2: Link children to their parents
    data.forEach((item) => {
      const parentID = item.parentGroupID;

      if (parentID && nodesMap[parentID]) {
        // Add to parent's children array
        nodesMap[parentID].children.push(nodesMap[item.guid]);
      } else {
        // No parentGroupID or parent not found; treat as root node
        rootNodes.push(nodesMap[item.guid]);
      }
    });

    return rootNodes;
  };



  console.log(ledgerData, "lllllllll");
  console.log(groupAlign, "ggggg");
  const align = () => {
    const addLedgersToMatchingGroups = (groupItem) => {
      ledgerData.forEach((ledger) => {
        if (groupItem.guid === ledger.groupID?.guid) {
          groupItem.children = groupItem.children || [];
          groupItem.children.push({ ...ledger, type: 'ledger', 
             });
          console.log(`Added ledger ${ledger.name} to group ${groupItem.name}`);
        }
      });
 
      if (groupItem.children && groupItem.children.length > 0) {
        groupItem.children.forEach(addLedgersToMatchingGroups);
      }
    };
 
    if (groupAlign[0] && groupAlign[0].children) {
      groupAlign.forEach(addLedgersToMatchingGroups);
    }
    settreeData(groupAlign[0])
  };
  



  const handleGroupList = async () => {
    try {
      const response = await getAccountgroup();
      if (Array.isArray(response)) {
        const organizedGroups = organizeGroupsByParent(response);
        setgroupAlign(organizedGroups);
        setGroupData(response);
      } else {
        setGroupData([]);
      }
    } catch (error) {
      console.error(error?.response?.data?.Message || "Something went wrong");
      setGroupData([]);
    }
  };
  

  const handleLedgerList = async () => {
    try {
      const response = await getAccountledger();
      console.log(response, "resssss");

      // setLedgerData(transformLedgerData(Array.isArray(response) ? response : []));
      setLedgerData(Array.isArray(response) ? response : []);


    } catch (error) {
      console.error(error?.response?.data?.Message || "Error fetching ledger data");
      setLedgerData([]);
    }
  };
  const handleFormSubmit = async (formData) => {
    const objledger = new AccLedger();
    const objgroup = new AccGroup();
    const objcompany = new Company();
    objgroup.ParentGroupID = formData.group;
    objgroup.ShowDetailsInFinalAccounts = false;
    objledger.id = 1;
    // objledger.id = LedgerMode=='edit'? singleLedger.id: 1;
    objledger.guid = LedgerMode=='edit'? singleLedger.guid: "";
    objledger.Name = formData.name;
    objledger.GroupID = GroupData?.find(e => e.guid === formData.group);
    objledger.IsDebit = formData.isDebit== true? true : false;
    objledger.Amount = formData.amount;
    objledger.CompanyID = companyInfolist[0];
    objledger.IsFixed = false;

    try {
      const response = await addAccountLedger(objledger);
      if (response.data === true) {
        toast.success("Success");
        handleLedgerList();
        handleGroupList()
        setLedgerMode('add')
        reset();
      } else if (response.data === false) {
        toast.error("Failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "Something went wrong");
      console.error("Error handling category:", error?.response?.data?.Message || "Something went wrong");
    }
  };
  const renderNode = (node) => {
    return (
      <div>
        {node.name} {/* Render only the node name */}
      </div>
    );
  };
  const handleClickNode=(data)=>{
    console.log(data);
    setsingleLedger(data)
    if(data.type=='ledger'){
      setLedgerMode('edit')
      setValue('amount',data?.amount)
      setValue('group',data?.groupID?.guid)
      setValue('isDebit',data?.isDebit)
      setValue('name',data?.name)

    }
    else if(data.type=='group'){
      setgroupMode('Edit')
      setmodelOpen(true)
    }
  }
  const resetmodal=()=>{
    setLedgerMode('add')
    reset()
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header transfer mb-4">
          <div className="add-item d-flex align-items-center">
            <div className="page-title">
              <h4>Ledger</h4>
              <h6 className="text-muted">Add Ledger here</h6>
            </div>
          </div>
          <div className="page-btn m-3">
            <Link
              to="#"
              className="btn btn-success btn-sm"
              onClick={() => {
                setgroupMode("Add");
                setmodelOpen(true);
              }}
            >
              <PlusCircle className="me-2 " />
              Add Group
            </Link>
          </div>
          <ul className="table-top-head list-inline d-flex align-items-center">
            <li className="list-inline-item">
              <OverlayTrigger placement="top" overlay={() => renderTooltip("PDF")}>
                <Link onClick={() => exportPDF("Account Type")}>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="PDF Icon" />
                </Link>
              </OverlayTrigger>
            </li>
            <li className="list-inline-item">
              <OverlayTrigger placement="top" overlay={() => renderTooltip("Excel")}>
                <Link onClick={() => exportExcel("Account Type")}>
                  <ImageWithBasePath src="assets/img/icons/excel.svg" alt="Excel Icon" />
                </Link>
              </OverlayTrigger>
            </li>
            <li className="list-inline-item">
              <OverlayTrigger placement="top" overlay={() => renderTooltip("Print")}>
                <Link onClick={() => HandlePrint("Account Type")}>
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li>
            <li className="list-inline-item">
              <OverlayTrigger placement="top" overlay={() => renderTooltip("Refresh")}>
                <Link><RotateCcw /></Link>
              </OverlayTrigger>
            </li>
            <li className="list-inline-item">
              <OverlayTrigger placement="top" overlay={() => renderTooltip("Collapse")}>
                <Link><ChevronUp /></Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>

        <div className="row">
          {/* Form Section */}
          <div className="col-lg-6">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="mb-4 border border-silver rounded p-5">
              <div className="row g-3">
                <div className="col-12">
                  <label className="form-label">Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    {...register("name", { required: "Name is required" })}
                    className="form-control"

                  />
                  {errors?.name && (
                    <span className="error text-danger text-sm">{errors.name.message}</span>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label">Group <span className="text-danger">*</span></label>
                  <select
                    className="form-select "
                    {...register("group", { required: "Group is required" })}
                  >
                    <option value="" disabled>Select Group</option>
                    {GroupData?.map((category) => (
                      <option key={category.guid} value={category.guid}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {errors?.group && (
                    <span className="error text-danger text-sm">{errors.group.message}</span>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label">Amount</label>
                  <input
                    type="text"
                    {...register("amount")}
                    className="form-control"
                  />
                  <div className="form-check form-switch mt-2">
                    <input className="form-check-input" type="checkbox" {...register("isDebit")} />
                    <label className="form-check-label">{watch('isDebit')==true?'Debit':'Credit'}</label>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-3 mr-2">{LedgerMode=='add'?'Submit':'Update'}</button>
              <button  type="button"
                          className="btn btn-cancel mt-3"
                          onClick={resetmodal}>Reset</button>
            </form>
          </div>

          {/* FolderTree Section */}
          <div className="col-6">
            <div className="border rounded p-3" style={{ maxHeight: '400px', overflowY: 'auto' }} >
              {treeData && (
               <FolderTree
               data={treeData}
            
               onNameClick={(selected) => {handleClickNode(selected.nodeData)}}
               
               onToggle={(node) => console.log('Toggled:', node)}
               showCheckbox={false}
               getNodeKey={(node) => node.guid}
               readOnly  
             />
             
              )}

            </div>
          </div>
        </div>
      </div>

      {/* Modal for Adding Group */}
      {
        modelOpen &&

      <AddGroup 
      mode={groupMode}
      data={groupMode === "Edit" ? singleLedger : {}}
      handleClose={() => {setmodelOpen(false); setgroupMode('Add')}}
      handleGroupList={handleGroupList} GroupData={GroupData} />
      }
    </div>
  );
};

export default Ledger;
