

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import FolderTree from "react-folder-tree";
import { Link } from "react-router-dom";
import { ChevronUp, PlusCircle, RotateCcw } from "feather-icons-react/build/IconComponents";
import { renderTooltip, exportExcel, exportPDF, HandlePrint } from "../../redux/helper.js";
import { Controller, useForm } from "react-hook-form";
import { Select } from "antd";
import "react-folder-tree/dist/style.css"; // Import the styles

const Journal = () => {
    const { register, handleSubmit, control } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
     
    return (
        <div className="modal fade" id="add-journal">
            <div className="page-wrapper card p-4 shadow-sm">
                <div className="content">
                   <div className="card table-list-card shadow-sm">
                        <div className="card-body">
                           <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content p-3">
                                    <div className="modal-header border-0">
                                        <h4 className="modal-title">Add Journal</h4>
                                        <button type="button" className="close" >
                    <span aria-hidden="true">×</span>
                  </button>
                                      </div>
                                         <div className="modal-body">
                                           <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label>No <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("name")}
                                                        required
                                                    />
                                                </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label>Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        {...register("date")}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label>Location</label>
                                                    <select {...register("location")} className="form-control">
                                                        <option value="">Select location</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="table-responsive mb-3">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Ledger</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th>Narration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Map through items dynamically */}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="form-group">
                                                <label>Narration</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...register("narration")}
                                                />
                                            </div>
                                           

                                            <div className="d-flex justify-content-end mt-4">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Journal;